import React, { useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Link,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import UploadCSV from "../components/UploadCSV";

const InfoPage = ({ isOpen, onClose }) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUpload = () => {
    setUploadModalOpen(!uploadModalOpen);
  };

  const handleDownloadTemplateExcel = () => {
    const anchor = document.createElement("a");
    anchor.href = process.env.PUBLIC_URL + "TemplateVoorMeter.xlsx";
    anchor.download = "TemplateVoorMeter.xlsx";
    anchor.click();
  };

  const handleDownloadTemplateExcelPowerBI = () => {
    const anchor = document.createElement("a");
    anchor.href = process.env.PUBLIC_URL + "Template_figuren kwartierdata.pbix";
    anchor.download = "Template_figuren kwartierdata.pbix";
    anchor.click();
  };

  return (
    <MsalAuthenticationTemplate>
      <Box
        flex={7}
        p={{ xs: 0, md: 2 }}
        flexDirection="column"
        alignItems="center"
        mt={2}
      >
        {/* Stap 1: Excel Template Card */}
        <Card mb={2}>
          <CardContent>
            <Typography variant="h5" component="div">
              Stap 1.1: Download Template Excel File om data in juist formaat te
              plaatsen.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={handleDownloadTemplateExcel}
            >
              Excel Template
            </Button>
          </CardContent>
        </Card>

        {/* Stap 3: Upload CSV Card */}
        <Card mb={2}>
          <CardContent>
            <Typography variant="h5" component="div">
              Stap 1.2: Gebruik het sjabloon hierboven om een CSV-bestand te
              uploaden
            </Typography>
            <Button variant="contained" color="primary" onClick={toggleUpload}>
              Upload CSV File
            </Button>
          </CardContent>
        </Card>

        {/* Stap 2: PowerBI Template Card */}
        <Card mb={2}>
          <CardContent>
            <Typography variant="h5" component="div">
              Stap 1.3: Download Template PowerBI File om data te visualiseren
              na het downloaden van <Link href="/EAN-gegevens">EAN data</Link>.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={handleDownloadTemplateExcelPowerBI}
            >
              PowerBI Template
            </Button>
          </CardContent>
        </Card>

        {/* Render the UploadCSV component separately */}
        <UploadCSV isOpen={uploadModalOpen} onClose={toggleUpload} />
      </Box>
    </MsalAuthenticationTemplate>
  );
};

export default InfoPage;
