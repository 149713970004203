// api.js

const API_BASE_URL = "https://energie-api.ingenium-group.be";

// Wrap your API logic in a function component or a custom hook

// ------------------------------------------- METER -------------------------------------------

export const getAllMeter = async (accessToken, setError) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Meter`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      setError("Gegevens ophalen mislukt.");
      return [];
    }
  } catch (error) {
    setError(error.message);
    return [];
  }
};

export const postMeter = async (accessToken, setError, MeterInfo) => {
  try {
    const requestBody = JSON.stringify({
      meter: MeterInfo,
    });

    const response = await fetch(`${API_BASE_URL}/meter`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    if (!response.ok) {
      setError("Gegevens ophalen mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const patchMeter = async (accessToken, setError, UpdatedInfo) => {
  try {
    const response = await fetch(`${API_BASE_URL}/meter`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(UpdatedInfo),
    });

    if (!response.ok) {
      setError("Gegevens bijwerken mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const deleteMeter = async (accessToken, setError, MeterId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/meter?Id=${MeterId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      setError("Verwijderen van gebouw mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

// ------------------------------------------- GEBOUW -------------------------------------------

export const getAllGebouw = async (accessToken, setError) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Gebouw`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData.map((item) => ({
        id: item.id,
        omschrijving: item.omschrijving,
      }));
    } else {
      setError("Gegevens ophalen mislukt.");
      return [];
    }
  } catch (error) {
    setError(error.message);
    return [];
  }
};

export const getAllGebouwG = async (accessToken, setError) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Gebouw`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      setError("Gegevens ophalen mislukt.");
      return [];
    }
  } catch (error) {
    setError(error.message);
    return [];
  }
};

export const postGebouw = async (accessToken, setError, GebouwInfo) => {
  try {
    const requestBody = JSON.stringify(GebouwInfo);

    const response = await fetch(`${API_BASE_URL}/Gebouw`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    if (!response.ok) {
      setError("Gegevens ophalen mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const patchGebouw = async (accessToken, setError, UpdatedInfo) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Gebouw`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(UpdatedInfo),
    });

    if (!response.ok) {
      setError("Gegevens bijwerken mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const deleteGebouw = async (accessToken, setError, GebouwId) => {
  try {
    console.log(GebouwId);
    const response = await fetch(`${API_BASE_URL}/Gebouw?Id=${GebouwId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      console.log(response);
      setError("Verwijderen van gebouw mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

// ------------------------------------------- Project -------------------------------------------

export const getAllProject = async (accessToken, setError) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Project`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      setError("Gegevens ophalen mislukt.");
      return [];
    }
  } catch (error) {
    setError(error.message);
    return [];
  }
};

export const postProject = async (accessToken, setError, GebouwInfo) => {
  try {
    const requestBody = JSON.stringify(GebouwInfo);

    const response = await fetch(`${API_BASE_URL}/project`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    if (!response.ok) {
      setError("Gegevens ophalen mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const patchProject = async (accessToken, setError, UpdatedInfo) => {
  try {
    const response = await fetch(`${API_BASE_URL}/project`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(UpdatedInfo),
    });

    if (!response.ok) {
      setError("Gegevens bijwerken mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const deleteProject = async (accessToken, setError, projectId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/project?Id=${projectId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      setError("Verwijderen van gebouw mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

// ------------------------------------------- Project Gebouw -------------------------------------------
export const postProjectGebouw = async (accessToken, setError, GebouwInfo) => {
  try {
    const requestBody = JSON.stringify(GebouwInfo);
    // console.log(requestBody);
    const response = await fetch(`${API_BASE_URL}/ProjectGebouw`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    if (!response.ok) {
      setError("Gegevens ophalen mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const patchProjectGebouw = async (
  accessToken,
  setError,
  UpdatedInfo,
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/projectgebouw`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(UpdatedInfo),
    });

    if (!response.ok) {
      setError("Gegevens bijwerken mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const deleteProjectGebouw = async (accessToken, setError, projectId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/projectgebouw?Id=${projectId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (!response.ok) {
      setError("Verwijderen van gebouw mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const getAllProjectGebouw = async (accessToken, setError) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Projectgebouw`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      setError("Gegevens ophalen mislukt.");
      return [];
    }
  } catch (error) {
    setError(error.message);
    return [];
  }
};

// ------------------------------------------- POST METER DATA -------------------------------------------

export const postMeterDataBatch = async (accessToken, setError, MeterInfo) => {
  try {
    const requestBody = JSON.stringify(MeterInfo);
    // console.log(requestBody);
    const response = await fetch(`${API_BASE_URL}/Meting`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    if (!response.ok) {
      setError("Gegevens ophalen mislukt.");
    }
  } catch (error) {
    setError(error.message);
  }
};
