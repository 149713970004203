import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const PrivateRoutes = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Outlet />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to="/login" />
      </UnauthenticatedTemplate>
    </>
  );
};

export default PrivateRoutes;
