import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";

import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../msal/msalConfig";
import {
  getAllProject,
  postProject,
  patchProject,
  deleteProject,
} from "../API/api";

const ProjectTable = ({ onProjectSelect }) => {
  const { instance, accounts } = useMsal();

  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newProjectData, setNewProjectData] = useState({
    id: 0,
    projectnummer: "",
    omschrijving: "",
  });
  const [, setError] = useState(null);

  const getAllData = useCallback(async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const projectData = await getAllProject(
        tokenResponse.accessToken,
        setError,
      );
      setTableData(projectData);
    } catch (error) {
      setError(error.message);
    }
  }, [instance, accounts, setError]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const handleOpenEditModal = (row) => {
    setSelectedRow(row);
    setEditData({
      id: row.id,
      omschrijving: row.omschrijving || "",
      ionUrl: row.ionUrl || "", // Make sure to include all fields here
      projectnummer: row.projectnummer || "",
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleSaveEdit = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      await patchProject(tokenResponse.accessToken, setError, editData);
      await getAllData();
      handleCloseEditModal();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteRow = async (row) => {
    if (
      !window.confirm(`Are you sure you want to delete ${row.omschrijving}?`)
    ) {
      return;
    }

    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      await deleteProject(tokenResponse.accessToken, setError, row.id);
      await getAllData();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleRowClick = (params) => {
    const isSameRow = selectedRow && selectedRow.id === params.row.id;
    setSelectedRow(isSameRow ? null : params.row);
    onProjectSelect(isSameRow ? null : params.row);
  };

  const handleCreate = () => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
    setNewProjectData({
      id: 0,
      projectnummer: "",
      omschrijving: "",
    });
  };

  const handleSaveCreate = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      // Add logic to create a new project with the provided data
      // For now, let's log the new project data
      // console.log("New Project Data:", newProjectData);

      handleCloseCreateModal();
      await postProject(tokenResponse.accessToken, setError, newProjectData);
      await getAllData(); // Refresh the table data after creating a new project
    } catch (error) {
      setError(error.message);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "omschrijving", headerName: "Description", width: 300 },
    { field: "ionUrl", headerName: "ionUrl", width: 400 },
    { field: "oppervlakte", headerName: "Oppervlakte", width: 200 },
    {
      field: "projectnummer",
      headerName: "Project Nummer",
      width: 200,
    },
    // {
    //   field: "gebouwen",
    //   headerName: "Gebouwen",
    //   width: 200,
    //   renderCell: (params) => (
    //     <ul>
    //       {params.row.gebouwen.map((gebouw) => (
    //         <li key={gebouw.id}>{gebouw.omschrijving}</li>
    //       ))}
    //     </ul>
    //   ),
    // },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Edit />}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenEditModal(params.row);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          startIcon={<Delete />}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteRow(params.row);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleCreate}
        style={{ marginBottom: "16px" }}
      >
        Create
      </Button>

      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 30]}
        disableSelectionOnClick
        autoHeight
        onRowClick={handleRowClick}
      />

      <Dialog open={editModalOpen} onClose={handleCloseEditModal}>
        <DialogTitle>Edit Project</DialogTitle>
        <DialogContent>
          <TextField
            label="Description"
            value={editData.omschrijving || ""}
            onChange={(e) =>
              setEditData({ ...editData, omschrijving: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="ionUrl"
            value={editData.ionUrl || ""}
            onChange={(e) =>
              setEditData({ ...editData, ionUrl: e.target.value })
            }
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Project Nummer"
            value={editData.projectnummer || ""}
            onChange={(e) =>
              setEditData({ ...editData, projectnummer: e.target.value })
            }
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>Cancel</Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={createModalOpen} onClose={handleCloseCreateModal}>
        <DialogTitle>Create Project</DialogTitle>
        <DialogContent>
          <TextField
            label="Project Nummer"
            value={newProjectData.projectnummer}
            onChange={(e) =>
              setNewProjectData({
                ...newProjectData,
                projectnummer: e.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newProjectData.omschrijving}
            onChange={(e) =>
              setNewProjectData({
                ...newProjectData,
                omschrijving: e.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateModal}>Cancel</Button>
          <Button onClick={handleSaveCreate} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectTable;
