import BuildingInfo from "../components/BuildingInfo";
import ExportCSV from "../components/ExportCSV";

import { Box } from "@mui/material";

import React, { useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

const EANData = () => {
  const [selectedEANs, setSelectedEANs] = useState({});

  const handleSelectedEANsUpdate = (updatedSelectedEANs) => {
    // Handle the updated selected EANs in the parent component
    setSelectedEANs(updatedSelectedEANs);
    // console.log(updatedSelectedEANs);
  };
  return (
    // <Box>
    <Box flex={8} p={{ xs: 0, md: 2 }}>
      <MsalAuthenticationTemplate>
        <ExportCSV selectedEANs={selectedEANs} />
        <BuildingInfo onSelectedEANsUpdate={handleSelectedEANsUpdate} />
        {/* <div>Selected EANs: {JSON.stringify(selectedEANs)}</div> */}
      </MsalAuthenticationTemplate>
    </Box>
  );
};

export default EANData;
