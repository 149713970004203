/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, IconButton, MenuItem, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msal/msalConfig";
import { CreateNewBuilding } from "./CreateNewBuilding";

import {
  getAllGebouwG,
  postGebouw,
  patchGebouw,
  deleteGebouw,
} from "../API/api";

import {
  validateRequired,
  validateEmail,
  validateAge,
} from "../Validation/Validation";

export const categorieType = [
  "kantoor",
  "cultureel centrum",
  "onderwijs",
  "musea",
  "WZC (Woonzorgcentrum)",
  "dienstencentra",
  "gevangenis",
  "kinderdagverblijf",
  "ziekenhuis",
  "residentieel",
  "sporthal",
  "binnenzwembad",
  "openluchtzwembad",
  "winkel",
  "hotel",
  "labo",
];

const BuildingTable = () => {
  const { instance, accounts } = useMsal();

  // const [data, setData] = useState(() => data);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [, setError] = useState(null);

  // ------------------------------------------------ Get ALL Building Info API  ------------------------------------------------

  const GetAllGebouw = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const jsonData = await getAllGebouwG(tokenResponse.accessToken, setError);

      setTableData(jsonData);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    GetAllGebouw();
  }, []);

  // ------------------------------------------------ ADD NEW BUILDING ------------------------------------------------

  const handleCreateNewRow = async (values) => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      await postGebouw(tokenResponse.accessToken, setError, values);
      await GetAllGebouw();
    } catch (error) {
      // Handle any unexpected errors, e.g., network issues.
      console.error("An error occurred:", error);
    }
    // setTableData([...tableData]);
  };

  // ------------------------------------------------ PATCH BUILDING ------------------------------------------------

  const handleSaveRowEdits = async ({ exitEditingMode, values }) => {
    if (!Object.keys(validationErrors).length) {
      exitEditingMode(); //required to exit editing mode and close modal

      try {
        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        await patchGebouw(tokenResponse.accessToken, setError, values);
        await GetAllGebouw();
      } catch (error) {
        // Handle any unexpected errors, e.g., network issues.
        console.error("An error occurred:", error);
      }
      // console.log(values);
      // Push the value back to API to patch new values.
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  // ------------------------------------------------ DELETE BUILDING ------------------------------------------------

  const handleDeleteRow = useCallback(async (row) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${row.getValue("omschrijving")}`,
      )
    ) {
      return;
    }

    const value = tableData[row.index];
    const gebouwID = value.id;

    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0], // You should define accounts array
      });
      await deleteGebouw(tokenResponse.accessToken, setError, gebouwID);
      await GetAllGebouw();

      const updatedTableData = [...tableData];
      updatedTableData.splice(row.index, 1);
      // setTableData(updatedTableData); // Update the local data in your state.

      // After updating the local data, you may want to re-render the table or reflect the changes in your UI.
      // Optionally trigger a re-render or UI update here.
    } catch (error) {
      // Handle any unexpected errors, e.g., network issues.
      console.error("An error occurred:", error);
    }
  });

  // ------------------------------------------------ Validation ------------------------------------------------

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "email"
              ? validateEmail(event.target.value)
              : cell.column.id === "age"
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "ID", // Updated header text
      //   size: 80,
      //   enableEditing: false,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // },
      {
        accessorKey: "omschrijving",
        header: "Description", // Updated header text
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "straatnaam",
        header: "Street Name", // Updated header text
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        // enableGrouping: false,
      },
      {
        accessorKey: "huisnummer",
        header: "House Number", // Updated header text
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
      },
      {
        accessorKey: "postcode",
        header: "Postal Code", // Updated header text
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
      },
      {
        accessorKey: "gemeente",
        header: "City  ", // Updated header text
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "land",
        header: "country", // Updated header text
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "oppervlakte",
        header: "Surface Area", // Updated header text
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
      },
      {
        accessorKey: "categorie",
        header: "Category", // Updated header text
        muiTableBodyCellEditTextFieldProps: {
          select: true, // Ensure select is set to true for a dropdown
          children: categorieType.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          )),
        },
      },
    ],
    [getCommonEditTextFieldProps],
  );
  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New Building
          </Button>
        )}
      />
      <CreateNewBuilding
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

export default BuildingTable;
