// src/App.js
import React, { useState } from "react";
import "./App.css";

import { Routes, Route, Navigate } from "react-router-dom";

import Sidebar from "./components/Sidebar";

// import UploadExcel from "./components/UploadExcel";
import Header from "./components/Header";

import EANData from "./Pages/EAN_Data_Page";
import BuildingPage from "./Pages/Building_Info_Page";
import LoginPage from "./Pages/Login_Page";
import MeterPage from "./Pages/Meter_Info_Page";
import ProjectInfo from "./Pages/Project_Info_Page";
import InfoPage from "./Pages/Info_Pages";

import PrivateRoutes from "./utils/PrivateRoutes";

import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { AppBar, Stack, Divider } from "@mui/material";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const { instance } = useMsal();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };
  const toggleUpload = () => {
    setUploadModalOpen(!uploadModalOpen);
  };

  return (
    <div className="App">
      <AppBar position="static">
        <Header
          toggleSidebar={toggleSidebar}
          toggleUpload={toggleUpload}
          handleLogout={handleLogout}
        />
      </AppBar>

      <AuthenticatedTemplate>
        {/* <UploadCSV isOpen={uploadModalOpen} onClose={toggleUpload} /> */}
        {/* <UploadExcel /> */}
        <Stack
          direction="row"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="flex-start"
        >
          <Sidebar isOpen={isOpen} onClose={toggleSidebar} />
          {/* ----------------------------------------- SIDEBAR ----------------------------------------- */}
          {/* ----------------------------------------- UPLOAD MODAL ----------------------------------------- */}
          <Routes>
            <Route element={<PrivateRoutes />}>
              {/* <Route path="/" element={<LoginPage />} /> */}

              <Route path="/Gebouwenlijst" element={<BuildingPage />} />
              <Route path="/EAN-gegevens" element={<EANData />} />
              <Route path="/Meterlijst" element={<MeterPage />} />
              <Route path="/Projectlijst" element={<ProjectInfo />} />
              <Route path="/InfoPage" element={<InfoPage />} />
            </Route>
          </Routes>
        </Stack>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
