import { MsalAuthenticationTemplate } from "@azure/msal-react";
import MeterTable from "../components/MeterTable";
import { Box } from "@mui/material";

import React from "react";

const MeterPage = () => {
  return (
    <Box flex={7} p={{ xs: 0, md: 2 }}>
      <MsalAuthenticationTemplate>
        <div>
          {/* <Container maxWidth="xl"> */}
          {/* <BuildingForm /> */}
          {/* <BuildingTable /> */}
          <MeterTable />
          {/* </Container> */}
        </div>
      </MsalAuthenticationTemplate>
    </Box>
  );
};

export default MeterPage;
