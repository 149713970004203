// Sidebar.jsx
import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
} from "@mui/material";

import ListAltIcon from "@mui/icons-material/ListAlt";

import { useNavigate } from "react-router-dom";

const listItemStyle = {
  color: "black",
};

const listItemTextStyle = {
  fontSize: "1rem",
};

const dividerStyle = {
  backgroundColor: "white",
};

const Sidebar = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const handleListItemClick = (path) => {
    navigate(path);
    onClose(); // Close the sidebar
  };

  return (
    <Box flex={1} p={2} sx={{ display: { xs: "none", sm: "block" } }}>
      <Box>
        <Divider />
        <ListItem>
          <ListItemText primary="Stap 1" sx={listItemTextStyle} />
        </ListItem>

        <ListItem
          button
          sx={listItemStyle}
          onClick={() => handleListItemClick("/InfoPage")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Template  Upload" sx={listItemTextStyle} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Stap 2" sx={listItemTextStyle} />
        </ListItem>

        <ListItem
          button
          sx={listItemStyle}
          onClick={() => handleListItemClick("/Gebouwenlijst")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Gebouw Info" sx={listItemTextStyle} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Stap 3" sx={listItemTextStyle} />
        </ListItem>
        <ListItem
          button
          sx={listItemStyle}
          onClick={() => handleListItemClick("/Projectlijst")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Project Info" sx={listItemTextStyle} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Stap 4" sx={listItemTextStyle} />
        </ListItem>
        <ListItem
          button
          sx={listItemStyle}
          onClick={() => handleListItemClick("/Meterlijst")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Meter Info" sx={listItemTextStyle} />
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemText primary="Stap 5" sx={listItemTextStyle} />
        </ListItem>
        <ListItem
          button
          sx={listItemStyle}
          onClick={() => handleListItemClick("/EAN-gegevens")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText
            primary="Extractie van EAN-gegevens"
            sx={listItemTextStyle}
          />
        </ListItem>

        <Divider sx={dividerStyle} />
      </Box>
    </Box>
  );
};

export default Sidebar;
