import React, { useState } from "react";

import "./styles.css";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msal/msalConfig";

import { postMeterDataBatch } from "../API/api";

const UploadCSV = ({ isOpen, onClose }) => {
  const { instance, accounts } = useMsal();

  const [, setError] = useState(null);

  // const postMeterDataBatch = async (MeterInfo) => {
  //   try {
  //     // Use the active account to get a token
  //     const tokenResponse = await instance.acquireTokenSilent({
  //       ...loginRequest,
  //       account: accounts[0], // You should define accounts array
  //     });
  //     // console.log(tokenResponse.accessToken);
  //     // const requestBody = JSON.stringify({
  //     //   metingen: MeterInfo, // Use MeterInfo as the value for the 'meter' key
  //     // });
  //     const requestBody = JSON.stringify(MeterInfo);
  //     //console.log(requestBody);
  //     const response = await fetch(
  //       "https://energie-api.ingenium-group.be/Meting",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${tokenResponse.accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: requestBody,
  //       },
  //     );

  //     if (response.ok) {
  //       //console.log(response);
  //     } else {
  //       setError("Gegevens ophalen mislukt.");
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // };

  const splitIntoBatches = (array, batchSize) => {
    const batches = [];
    for (let i = 0; i < array.length; i += batchSize) {
      batches.push(array.slice(i, i + batchSize));
    }
    return batches;
  };

  const onSubmit = async (data, file) => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0], // You should define accounts array
      });

      const tempJSONData = data.validData;
      const measuredValues = [];
      console.log(tempJSONData);
      // tempJSONData.forEach((item) => {

      //   const dataObject = new Date(item.time);
      //   const serialNumber =
      //     (dataObject - new Date("1899-12-30")) / (24 * 60 * 60 * 1000);
      //   const metingModel = {};

      //   if (item.interval === "15") {
      //     const dateObject = item.time;

      //     const parts = dateObject.split(/[\s/:]/);
      //     const day = parseInt(parts[0], 10);
      //     const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
      //     const year = parseInt(parts[2], 10);
      //     const hours = parseInt(parts[3], 10);
      //     const minutes = parseInt(parts[4], 10);
      //     const seconds = parseInt(parts[5], 10);

      //     const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

      //     metingModel.id = 0;

      //     metingModel.ean = item.EAN;
      //     metingModel.type = item.type;
      //     // Time: ExcelDateToJSDate(serialNumber),
      //     metingModel.time = isoString;
      //     metingModel.power = parseFloat(item.power) || 0;

      //     metingModel.consumption = parseFloat(item.consumption) || 0;
      //     metingModel.volume = parseFloat(item.volume) || 0;
      //     metingModel.interval = 15;
      //     metingModel.sourceInterval = 15;
      //     metingModel.sourceFile = file.path;
      //     measuredValues.push(metingModel);
      //   } else if (item.interval === "60") {
      //     const dateObject = item.time;

      //     const parts = dateObject.split(/[\s/:]/);
      //     const day = parseInt(parts[0], 10);
      //     const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
      //     const year = parseInt(parts[2], 10);
      //     const hours = parseInt(parts[3], 10);
      //     const minutes = parseInt(parts[4], 10);
      //     const seconds = parseInt(parts[5], 10);

      //     // Create a Date object with the local time
      //     // const localDate = new Date(year, month, day, hours, minutes, seconds);

      //     // Get the UTC time
      //     // const utcDate = new Date(localDate.toUTCString().toString());

      //     const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
      //     console.log(item.time + " " + isoString); // Use the corrected 'month' variable here
      //     const baseMetingModel = {
      //       id: 0,
      //       ean: item.EAN,
      //       type: item.type,
      //       // Time: ExcelDateToJSDate(serialNumber),
      //       time: isoString,
      //       power: parseFloat(item.power) / 4 || 0,
      //       consumption: parseFloat(item.consumption) / 4 || 0,
      //       volume: parseFloat(item.volume) / 4 || 0,
      //       interval: 15,
      //       sourceInterval: 60,
      //       sourceFile: file.path,
      //     };
      //     //console.log(baseMetingModel);

      //     for (let i = 0; i < 4; i++) {
      //       const metingModel = { ...baseMetingModel };

      //       // Convert the time string to a Date object
      //       const timeAsDate = new Date(metingModel.time);

      //       // Increment the time by i * 15 minutes
      //       timeAsDate.setMinutes(timeAsDate.getMinutes() + i * 15);

      //       // Convert the updated Date object back to ISO string
      //       metingModel.time = timeAsDate.toISOString();

      //       //console.log(metingModel);

      //       measuredValues.push(metingModel);
      //     }
      //   }
      // });
      // console.log(measuredValues);
      //console.log(measuredValues);

      tempJSONData.forEach((item) => {
        const metingModel = {};

        if (item.interval === "15") {
          const switchedTimeString = item.time.replace(
            /^(\d{2})\/(\d{2})/,
            "$2/$1",
          );

          const dateObject = new Date(switchedTimeString);
          const year = dateObject.getFullYear();
          const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
          const day = dateObject.getDate().toString().padStart(2, "0");
          const hours = dateObject.getHours().toString().padStart(2, "0");
          const minutes = dateObject.getMinutes().toString().padStart(2, "0");
          const seconds = dateObject.getSeconds().toString().padStart(2, "0");

          const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

          metingModel.id = 0;

          metingModel.ean = item.EAN;
          metingModel.type = item.type;
          // Time: ExcelDateToJSDate(serialNumber),
          metingModel.time = isoString;
          metingModel.power = parseFloat(item.power) || 0;
          metingModel.consumption = parseFloat(item.consumption) || 0;
          metingModel.volume = parseFloat(item.volume) || 0;
          metingModel.interval = 15;
          metingModel.sourceInterval = 60;
          metingModel.sourceFile = file.path;
          measuredValues.push(metingModel);
        } else if (item.interval === "60") {
          const switchedTimeString = item.time.replace(
            /^(\d{2})\/(\d{2})/,
            "$2/$1",
          );

          const dateObject = new Date(switchedTimeString);
          const year = dateObject.getFullYear();
          const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
          const day = dateObject.getDate().toString().padStart(2, "0");
          const hours = dateObject.getHours().toString().padStart(2, "0");
          const minutes = dateObject.getMinutes().toString().padStart(2, "0");
          const seconds = dateObject.getSeconds().toString().padStart(2, "0");

          const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
          // console.log("switchedTimeString " + switchedTimeString);
          // console.log("DataObject " + dateObject);
          // console.log("DataObject local " + dateObject.toLocaleString());
          // console.log("item.time " + item.time);
          // console.log("Iso " + isoString);
          const baseMetingModel = {
            id: 0,
            ean: item.EAN,
            type: item.type,
            // Time: ExcelDateToJSDate(serialNumber),
            time: isoString,
            power: parseFloat(item.power) / 4 || 0,
            consumption: parseFloat(item.consumption) / 4 || 0,
            volume: parseFloat(item.volume) / 4 || 0,
            interval: 15,
            sourceInterval: 60,
            sourceFile: file.path,
          };
          //console.log(baseMetingModel);

          for (let i = 0; i < 4; i++) {
            const metingModel = { ...baseMetingModel };

            // Convert the time string to a Date object
            const timeAsDate = new Date(metingModel.time);

            // Increment the time by i * 15 minutes
            timeAsDate.setMinutes(timeAsDate.getMinutes() + i * 15);

            // Convert the updated Date object back to ISO string
            metingModel.time = timeAsDate.toISOString();

            //console.log(metingModel);

            measuredValues.push(metingModel);
          }
        }
      });

      const batches = splitIntoBatches(measuredValues, 4500);
      console.log(measuredValues);

      for (const batch of batches) {
        await postMeterDataBatch(tokenResponse.accessToken, setError, {
          metingen: { metingen: batch },
        });
      }
      // postMeterData({ metingen: { metingen: measuredValues } });
    } catch (error) {
      setError("Error reading the file content");
    }
  };

  const fields = [
    {
      // Visible in table header and when matching columns.
      label: "EAN",
      // This is the key used for this field when we call onSubmit.
      key: "EAN",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1234567890123",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "EAN is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Type",
      // This is the key used for this field when we call onSubmit.
      key: "type",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Product",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Type is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in the table header and when matching columns.
      label: "Time",
      // This is the key used for this field when we call onSubmit.
      key: "time",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "2023-09-29 01:00:00",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Time is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Power",
      // This is the key used for this field when we call onSubmit.
      key: "power",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "100",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Power is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Consumption",
      // This is the key used for this field when we call onSubmit.
      key: "consumption",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "50",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Consumption is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Volume",
      // This is the key used for this field when we call onSubmit.
      key: "volume",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "10",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Volume is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Interval",
      // This is the key used for this field when we call onSubmit.
      key: "interval",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "30 minutes",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Interval is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
  ];

  return (
    <div className="csvimport">
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        fields={fields}
        customTheme={{
          colors: {
            background: "white",
            rsi: {
              // your brand colors should go here
              500: "teal",
            },
          },
        }}
      />
    </div>
  );
};
export default UploadCSV;
