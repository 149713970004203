import React, { useState } from "react";
import {
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

const categorieType = ["ELE", "GAS", "WAR", "PZO", "IZO"];
export const CreateNewMeter = ({
  open,
  onClose,
  onSubmit,
  gebouwData,
  error,
}) => {
  const [MeterData, setMeterData] = useState({
    id: 0,
    ean: "",
    type: "",
    gebouwID: "",
  });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setMeterData({ ...MeterData, [name]: value });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "id") {
      // If the input field is "oppervlakte," parse the value as a number
      setMeterData({ ...MeterData, [name]: parseFloat(value) });
    } else {
      // For other input fields (e.g., "land"), keep the value as a string
      setMeterData({ ...MeterData, [name]: value });
    }
  };
  const handleFormSubmit = () => {
    onSubmit(MeterData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Voeg een nieuw gebouw toe</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            name="id"
            label="ID"
            type="number"
            value={MeterData.id}
            onChange={handleInputChange}
          />
          <TextField
            name="ean"
            label="EAN"
            value={MeterData.ean}
            onChange={handleInputChange}
            error={error ? true : false}
            helperText={error}
          />
          <Select
            name="type"
            label="type"
            value={MeterData.type}
            onChange={handleInputChange}
          >
            {categorieType.map((categorie) => (
              <MenuItem key={categorie} value={categorie}>
                {categorie}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="gebouwID"
            label="gebouwID"
            value={MeterData.gebouwID}
            onChange={handleInputChange}
          >
            {gebouwData.map(
              (
                categorie, // Use gebouwData to populate the Select options
              ) => (
                <MenuItem key={categorie.id} value={categorie.id}>
                  {/* {categorie.} */}
                  {categorie.omschrijving}
                </MenuItem>
              ),
            )}
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuleren
        </Button>
        <Button onClick={handleFormSubmit} color="primary">
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
