import React, { useState } from "react";
import {
  Select,
  MenuItem,
  CardContent,
  Card,
  Chip,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";

export const GebouwCard = ({ gebouwData, meterData, updateSelectedEANs }) => {
  // const [selectedEANs, setSelectedEANs] = useState([]);
  const [localSelectedEANs, setLocalSelectedEANs] = useState([]); // Local state for selected EANs

  const handleChange = (event) => {
    // Update the localSelectedEANs state
    setLocalSelectedEANs(event.target.value);
    // Call the updateSelectedEANs function in BuildingInfo with the selected EAN number
    updateSelectedEANs(event.target.value);
  };

  const selectAllEANs = () => {
    console.log(meterData);

    const eanNumbers = meterData
      .filter((meter) => meter.gebouwID === gebouwData.gebouwId)
      .map((meter) => meter.ean);
    console.log(eanNumbers);
    setLocalSelectedEANs(eanNumbers);
    updateSelectedEANs(eanNumbers);
  };

  return (
    <Card
      style={{
        width: "100%", // Stel de breedte in op 100% om dezelfde breedte te garanderen
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%", // Stel de hoogte in op 100% om dezelfde hoogte te garanderen
      }}
    >
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Gebouw Informatie
              </TableCell>
              <TableCell>{gebouwData.omschrijving}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Gebouw Project
              </TableCell>
              <TableCell>{gebouwData.projectInfo.omschrijving}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Straatnaam</TableCell>
              <TableCell>{gebouwData.straatnaam}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Huisnummer</TableCell>
              <TableCell>{gebouwData.huisnummer}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Postcode</TableCell>
              <TableCell>{gebouwData.postcode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gemeente</TableCell>
              <TableCell>{gebouwData.gemeente}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Land</TableCell>
              <TableCell>{gebouwData.land}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Oppervlakte</TableCell>
              <TableCell>{gebouwData.oppervlakte}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Categorie</TableCell>
              <TableCell>{gebouwData.categorie}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <FormControl fullWidth style={{ marginTop: "16px" }}>
          <InputLabel id="ean-select-label">Select EAN Numbers</InputLabel>
          <Select
            labelId="ean-select-label"
            id="ean-select"
            multiple
            value={localSelectedEANs} // Use the local state for value
            onChange={handleChange}
            renderValue={(selected) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} color="primary" />
                ))}
              </div>
            )}
          >
            {meterData
              .filter((meter) => meter.gebouwID === gebouwData.gebouwId)
              .map((meter) => (
                <MenuItem key={meter.id} value={meter.ean}>
                  {meter.ean}
                </MenuItem>
              ))}
          </Select>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: "16px" }}
            onClick={selectAllEANs}
          >
            Select All
          </Button>
        </FormControl>
      </CardContent>
    </Card>
  );
};
