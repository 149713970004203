import React, { useState } from "react";
import { Box } from "@mui/material";
import ProjectTable from "../components/projectTable";
import ProjectGebouwTable from "../components/ProjectGebouwTable";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

const ProjectInfo = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleProjectSelect = (project) => {
    if (project) {
      setSelectedProject(project.id);
    }
  };

  return (
    <Box flex={7} p={{ xs: 0, md: 2 }}>
      <MsalAuthenticationTemplate>
        <ProjectTable onProjectSelect={handleProjectSelect} />
        {selectedProject && <ProjectGebouwTable projectId={selectedProject} />}
        {/* Add other components if needed */}
      </MsalAuthenticationTemplate>
    </Box>
  );
};

export default ProjectInfo;
