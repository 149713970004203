/* eslint-disable react-hooks/exhaustive-deps */
// MeterTable.js
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, IconButton, Tooltip, MenuItem } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msal/msalConfig";
import { CreateNewMeter } from "./CreateNewMeter";
import {
  getAllMeter,
  getAllGebouw,
  postMeter,
  patchMeter,
  deleteMeter,
} from "../API/api";
import {
  validateRequired,
  validateEmail,
  validateAge,
} from "../Validation/Validation";

const MeterTable = () => {
  const { instance, accounts } = useMsal();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [meterData, setMeterData] = useState([]);
  const [gebouwData, setGebouwData] = useState([]);
  const [omschrijvingLijst, setOmschrijvingLijst] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [, setError] = useState(null);
  const [errorEan, setErrorEan] = useState(null);
  const categoryMeter = ["ELE", "GAS", "WAR", "PZO", "IZO"];

  const GetAllMeter = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const jsonData = await getAllMeter(tokenResponse.accessToken, setError);
      setMeterData(jsonData);
    } catch (error) {
      setError(error.message);
    }
  };

  const GetAllGebouw = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const jsonData = await getAllGebouw(tokenResponse.accessToken, setError);
      const extractedData = jsonData.map((item) => ({
        id: item.id,
        omschrijving: item.omschrijving,
      }));
      setGebouwData(extractedData);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    GetAllMeter();
    GetAllGebouw();
  }, []);

  const MergeMeterGebouw = () => {
    const result = meterData.map((meter) => {
      const gebouwMatch = gebouwData.find(
        (gebouw) => gebouw.id === meter.gebouwID,
      );
      return {
        ...meter,
        omschrijving: gebouwMatch
          ? gebouwMatch.omschrijving
          : "Omschrijving niet gevonden",
      };
    });

    setTableData(result);
    const omschrijvingen = gebouwData.map((item) => item.omschrijving);
    const uniqueOmschrijvingen = [...new Set(omschrijvingen)];
    setOmschrijvingLijst(uniqueOmschrijvingen);
  };

  useEffect(() => {
    if (meterData.length > 0 && gebouwData.length > 0) {
      MergeMeterGebouw();
    }
  }, [meterData, gebouwData]);

  const PostMeter = async (MeterInfo) => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      await postMeter(tokenResponse.accessToken, setError, MeterInfo);
      await GetAllMeter();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCreateNewRow = async (values) => {
    try {
      const eanExists = tableData.some((row) => row.ean === values.ean);
      if (eanExists) {
        setErrorEan("EAN already exists");
      } else {
        setErrorEan(null);
        await PostMeter(values);
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("An error occurred:", error);
    }
  };

  const handleSaveRowEdits = async ({ exitEditingMode, values }) => {
    if (!Object.keys(validationErrors).length) {
      exitEditingMode();

      const updatedValues = { ...values };
      if (gebouwData && gebouwData.length) {
        const matchingGebouw = gebouwData.find(
          (gebouw) => gebouw.omschrijving === values.omschrijving,
        );
        if (matchingGebouw) {
          updatedValues.gebouwID = matchingGebouw.id;
          delete updatedValues.omschrijving;
        }
      }

      try {
        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const eanExists = tableData.some((row) => row.Type === values.type);
        if (eanExists) {
          setErrorEan("EAN already exists");
        } else {
          setErrorEan(null);
          await patchMeter(tokenResponse.accessToken, setError, updatedValues);
          await GetAllMeter();
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const DeleteMeter = async (MeterId, rowIndex) => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      await deleteMeter(tokenResponse.accessToken, setError, MeterId);
      await GetAllMeter();

      const updatedmeterData = [...meterData];
      updatedmeterData.splice(rowIndex, 1); // Use rowIndex instead of row.index
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteRow = useCallback(async (row) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${row.getValue("omschrijving")}`,
      )
    ) {
      return;
    }

    const value = meterData[row.index];
    const MeterId = value.id;

    try {
      await DeleteMeter(MeterId, row.index); // Pass row.index to DeleteMeter
    } catch (error) {
      console.error("An error occurred:", error);
    }
  });

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "email"
              ? validateEmail(event.target.value)
              : cell.column.id === "age"
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 80,
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "ean",
        header: "EAN Number",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 140,
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: categoryMeter.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: "omschrijving",
        header: "Building",
        size: 140,
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: omschrijvingLijst.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )),
        },
      },
    ],
    [getCommonEditTextFieldProps, omschrijvingLijst],
  );

  return (
    <>
      {errorEan && <div style={{ color: "red" }}>{errorEan}</div>}
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create new Meter
          </Button>
        )}
      />
      <CreateNewMeter
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        gebouwData={gebouwData}
        error={errorEan}
      />
    </>
  );
};

export default MeterTable;
