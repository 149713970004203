import React from "react";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import { AuthenticatedTemplate } from "@azure/msal-react"; // Import the AuthenticatedTemplate component

const Header = ({ toggleSidebar, toggleUpload, handleLogout }) => {
  return (
    <Toolbar>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Energy Dashboard
      </Typography>

      <AuthenticatedTemplate></AuthenticatedTemplate>
    </Toolbar>
  );
};

export default Header;
