import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msal/msalConfig";

const ExportCSV = ({ selectedEANs }) => {
  const { instance, accounts } = useMsal();
  const [, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataForEANs = async () => {
    setIsLoading(true);
    // console.log(selectedEANs);
    const arrayOfValues = [];
    const arrayOfOmschrijving = [];
    for (const key in selectedEANs) {
      if (selectedEANs.hasOwnProperty(key)) {
        const value = selectedEANs[key];

        if (value.hasOwnProperty("eans")) {
          const eanArray = value["eans"];

          // Skip if eanArray is not an array or the first element is an empty array or not a string
          if (
            !Array.isArray(eanArray) ||
            eanArray.length === 0 ||
            !eanArray[0] ||
            typeof eanArray[0][0] !== "string"
          ) {
            continue;
          }

          arrayOfValues.push(eanArray[0]);
        }

        if (value.hasOwnProperty("omschrijving")) {
          arrayOfOmschrijving.push(value["omschrijving"]);
        }
      }
    }

    const csvData = [];
    //console.log(arrayOfOmschrijving);

    for (const eanSubArray of arrayOfValues) {
      for (const ean of eanSubArray) {
        // console.log(ean); // Log each array of values

        try {
          const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const response = await fetch(
            `https://energie-api.ingenium-group.be/Meting?ean=${ean}`,
            {
              headers: {
                Authorization: `Bearer ${tokenResponse.accessToken}`,
              },
            },
          );

          if (response.ok) {
            const jsonData = await response.json();

            // console.table(jsonData);
            const modifiedData = jsonData.map((item) => {
              const ean = item.ean;
              let matchedEAN;
              let omschrijving, oppervlakte, categorie, type;

              // Find the selectedEANs entry that contains the ean
              for (const key in selectedEANs) {
                if (selectedEANs[key].eans[0].includes(ean)) {
                  matchedEAN = selectedEANs[key];
                  break;
                }
              }

              if (matchedEAN) {
                omschrijving = matchedEAN.omschrijving;
                oppervlakte = matchedEAN.oppervlakte;
                categorie = matchedEAN.categorie;
                type = item.type;
                // console.log(type);
              }

              return {
                id: item.id,
                omschrijving: omschrijving,
                oppervlakte: oppervlakte,
                categorie: categorie,
                type: type,
                ean: item.ean,
                // time: item.time,
                time: convertDateTimeFormat(item.time),
                power: item.power,
                consumption: item.consumption,
                volume: item.volume,
                interval: item.interval,
                sourceInterval: item.sourceInterval,
                sourceFile: item.sourceFile,
              };
            });

            csvData.push(modifiedData); // Collect data in the desired format
            // console.log(modifiedData);
            // ---------------------------------------------------------------- HIER GEBOUW TOEVOEGEN DENK IK----------------------------------------------------------------
          } else {
            console.error(
              `Failed to fetch data for EAN ${ean}:`,
              response.status,
            );
          }
        } catch (error) {
          console.error(`Failed to fetch data for EAN ${ean}:`, error);
        }
      }
    }

    setCsvData(csvData); // Update state with fetched data
    setIsLoading(false);
    downloadCSV(csvData); // Call the download function after fetching data
  };
  const convertDateTimeFormat = (inputDateTime) => {
    // Split the input datetime string into date and time parts
    const [datePart, timePart] = inputDateTime.split("T");

    // Split the date into year, month, and day
    const [year, month, day] = datePart.split("-");

    // Create the new date format (dd/mm/yyyy)
    const newDateFormat = `${day}/${month}/${year}`;

    // Combine the new date format and the time part
    const newDateTimeFormat = `${newDateFormat} ${timePart}`;

    return newDateTimeFormat;
  };

  const downloadCSV = (data) => {
    const transformed = [];

    for (const measurements of data) {
      for (const measurement of measurements) {
        const ean = measurement.ean;

        // Find the selectedEANs entry that contains the ean
        let matchedEAN;
        for (const key in selectedEANs) {
          if (selectedEANs[key].eans[0].includes(ean)) {
            matchedEAN = selectedEANs[key];
            break;
          }
        }

        const omschrijving = matchedEAN ? matchedEAN.omschrijving : "";
        const typeGebouw = matchedEAN ? matchedEAN.type : "";
        const vloeropp = matchedEAN ? matchedEAN.oppervlakte : "";

        transformed.push({
          Naamgebouw: omschrijving,
          Identifier: "EAN" + ean,
          "Type gebouw": typeGebouw,
          Vloeropp: vloeropp,
          Type: measurement.type,
          Time: measurement.time,
          Power:
            measurement.power !== null
              ? measurement.power.toFixed(2).replace(".", ",")
              : null,
          Consumption:
            measurement.consumption !== null
              ? measurement.consumption.toFixed(2).replace(".", ",")
              : null,
          Interval: measurement.interval,
        });
      }
    }

    const csvContent = [
      Object.keys(transformed[0]).join(";"), // Header row
      ...transformed.map((item) =>
        Object.values(item)
          .map((value) => JSON.stringify(value))
          .join(";"),
      ),
    ].join("\n");

    // Create a data URI for downloading
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "exported_data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={isLoading ? null : fetchDataForEANs}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Export and Download CSV"}
      </Button>
    </div>
  );
};

export default ExportCSV;
