/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msal/msalConfig";

import { GebouwCard } from "./GebouwCard";

import { getAllMeter, getAllProjectGebouw, getAllProject } from "../API/api";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2",
    },
  },
});

const BuildingInfo = ({ onSelectedEANsUpdate }) => {
  const { instance, accounts } = useMsal();

  const [meterData, setMeterData] = useState([]);
  const [gebouwData, setGebouwData] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [selectedTopics, setSelectedTopics] = useState([]);
  // const [allTopics, setAllTopics] = useState([]);

  const [fullData, setFullData] = useState([]);
  const [selectedEANs, setSelectedEANs] = useState({});
  const [, setError] = useState(null);

  const topics = [
    "omschrijving",
    "meters.ean",
    "straatnaam",
    "huisnummer",
    "postcode",
    "gemeente",
    "land",
    "projectInfo.omschrijving",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        const meterData = await getAllMeter(
          tokenResponse.accessToken,
          setError,
        );
        // const gebouwData = await getAllGebouwG(
        //   tokenResponse.accessToken,
        //   setError,
        // );

        const gebouwData = await getAllProjectGebouw(
          tokenResponse.accessToken,
          setError,
        );
        const projectData = await getAllProject(
          tokenResponse.accessToken,
          setError,
        );

        setMeterData(meterData);
        setGebouwData(gebouwData);
        setProjectData(projectData);

        const mergedData = mergeData(meterData, gebouwData, projectData);

        setFullData(mergedData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [instance, accounts]);

  const mergeData = (meterData, gebouwData, projectData) => {
    const meterMap = new Map();
    const projectMap = new Map();

    meterData.forEach((meter) => {
      const gebouwID = meter.gebouwID; // Use the same property name as in meterData

      if (!meterMap.has(gebouwID)) {
        meterMap.set(gebouwID, []);
      }
      meterMap.get(gebouwID).push(meter);
    });

    projectData.forEach((project) => {
      projectMap.set(project.id, project);
    });

    const mergedData = gebouwData.map((gebouw) => {
      const gebouwId = gebouw.gebouwId; // Use the same property name as in gebouwData
      if (meterMap.has(gebouwId)) {
        gebouw.meters = meterMap.get(gebouwId);
      } else {
        gebouw.meters = [];
      }

      if (projectMap.has(gebouw.ionProjectId)) {
        const projectInfo = projectMap.get(gebouw.ionProjectId);
        projectInfo.meters = gebouw.meters; // Add meters to projectInfo
        gebouw.projectInfo = projectInfo;
      } else {
        gebouw.projectInfo = {}; // Set an empty object if projectInfo doesn't exist
      }

      return gebouw;
    });

    return mergedData;
  };

  useEffect(() => {
    if (
      meterData.length > 0 &&
      gebouwData.length > 0 &&
      projectData.length > 0
    ) {
      mergeData(meterData, gebouwData, projectData);
    }
  }, [meterData, gebouwData, projectData]);

  const getSelectedMeterInfo = (selectedEAN) => {
    for (const building of gebouwData) {
      if (building.id === selectedEAN) {
        const selectedMeter =
          building.meters && building.meters.length > 0
            ? building.meters[0]
            : null;
        const meterInfo = {
          omschrijving: building.omschrijving,
          oppervlakte: building.oppervlakte,
          categorie: building.categorie,
          type: selectedMeter ? selectedMeter.type : null,
        };
        return meterInfo;
      }
    }
    return null;
  };

  const updateSelectedEANs = (ean, gebouwId) => {
    const meterID = meterData.find((item) => item.gebouwID === gebouwId)?.id;
    const selectedMeterInfo = getSelectedMeterInfo(gebouwId);

    const updatedSelectedEANs = { ...selectedEANs };

    //console.log(selectedMeterInfo);

    updatedSelectedEANs[meterID] = {
      omschrijving: selectedMeterInfo.omschrijving,
      oppervlakte: selectedMeterInfo.oppervlakte,
      categorie: selectedMeterInfo.categorie,
      type: selectedMeterInfo.type,
      eans: [ean],
    };

    setSelectedEANs(updatedSelectedEANs);
    onSelectedEANsUpdate(updatedSelectedEANs);
    // console.log(selectedEANs);
  };

  useEffect(() => {
    //console.log(selectedEANs);
  }, [selectedEANs]);
  const filteredGebouwData = fullData.filter((gebouw) => {
    const searchLower = searchInput.toLowerCase();

    if (
      gebouw.omschrijving.toLowerCase().includes(searchLower) ||
      (gebouw.meters &&
        gebouw.meters.some((meter) =>
          meter.ean.toLowerCase().includes(searchLower),
        )) ||
      gebouw.straatnaam.toLowerCase().includes(searchLower) ||
      gebouw.huisnummer.toLowerCase().includes(searchLower) ||
      gebouw.postcode.toLowerCase().includes(searchLower) ||
      gebouw.gemeente.toLowerCase().includes(searchLower) ||
      gebouw.land.toLowerCase().includes(searchLower) ||
      gebouw.projectInfo.omschrijving.toLowerCase().includes(searchLower)
    ) {
      return true;
    }

    return false;
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h5" component="div">
        <Link href="/InfoPage">PowerBI Template</Link>.
      </Typography>
      <TextField
        label="Search Gebouw"
        variant="outlined"
        fullWidth
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        style={{ marginBottom: "16px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2}>
        {filteredGebouwData.map((gebouw) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={gebouw.id}>
            <div className="gebouw-card-content">
              <GebouwCard
                gebouwData={gebouw}
                meterData={meterData}
                updateSelectedEANs={(ean) => updateSelectedEANs(ean, gebouw.id)}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};

export default BuildingInfo;
