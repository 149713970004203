/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import {
  getAllProject,
  getAllGebouwG,
  getAllProjectGebouw,
  postProjectGebouw,
  patchProjectGebouw,
  deleteProjectGebouw,
} from "../API/api";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msal/msalConfig";

const ProjectGebouwTable = ({ projectId }) => {
  const { instance, accounts } = useMsal();

  const [gebouwen, setGebouwen] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editGebouwData, setEditGebouwData] = useState({});
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newGebouwData, setNewGebouwData] = useState({
    id: 0,
    ionProjectId: 0, // Initialize with null
    gebouwId: 0, // Initialize with null
  });
  const [allGebouwOptions, setAllGebouwOptions] = useState([]);

  const [ionProjects, setIonProjects] = useState([]);
  const [, setError] = useState(null);

  useEffect(() => {
    // setGebouwen(projectId.gebouwen);
    // console.log(projectId);
    const fetchData = async () => {
      try {
        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        const projectData = await getAllProject(
          tokenResponse.accessToken,
          setError,
        );

        setIonProjects(projectData);
        const allGebouwData = await getAllGebouwG(tokenResponse.accessToken);
        setAllGebouwOptions(allGebouwData);

        // Fetch gebouwOptions from the API
        const gebouwData = await getAllProjectGebouw(tokenResponse.accessToken);
        // setGebouwOptions(gebouwData);
        // console.log(gebouwData);

        const filteredGebouwen = gebouwData.filter(
          (gebouw) => gebouw.ionProjectId === projectId,
        );

        // console.log(filteredGebouwen);
        setGebouwen(filteredGebouwen);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors as needed
      }
    };

    fetchData();
  }, [projectId]);

  const handleEditDialogOpen = (row) => {
    setSelectedRow(row);
    setEditGebouwData(row);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setSelectedRow(null);
    setEditGebouwData({});
    setEditDialogOpen(false);
  };

  const handleSaveEdit = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      // console.log(editGebouwData);
      const updatedGebouwData = {
        id: selectedRow.id, // Assuming selectedRow has the necessary ID
        ionProjectId: editGebouwData.ionProjectId,
        gebouwId: editGebouwData.gebouwId,
        // Add other properties as needed
      };

      await patchProjectGebouw(
        tokenResponse.accessToken,
        setError,
        updatedGebouwData,
      );

      setGebouwen((prevGebouwen) =>
        prevGebouwen.map((gebouw) =>
          gebouw.id === selectedRow.id
            ? { ...gebouw, ...editGebouwData }
            : gebouw,
        ),
      );
      handleEditDialogClose();
    } catch (error) {
      console.error("Error updating gebouw:", error);
    }
  };

  const handleDeleteRow = async (row) => {
    try {
      // console.log(row);
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      await deleteProjectGebouw(tokenResponse.accessToken, setError, row.id);
      setGebouwen((prevGebouwen) =>
        prevGebouwen.filter((gebouw) => gebouw.id !== row.id),
      );
    } catch (error) {
      console.error("Error deleting gebouw:", error);
    }
  };

  const handleCreateDialogOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleCreateDialogClose = () => {
    setNewGebouwData({
      id: 0,
      // Reset other fields as needed
    });
    setCreateDialogOpen(false);
  };

  const handleSaveCreate = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      await postProjectGebouw(
        tokenResponse.accessToken,
        setError,
        newGebouwData,
      );

      handleCreateDialogClose();
    } catch (error) {
      console.error("Error creating gebouw:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "omschrijving", headerName: "Omschrijving", width: 300 },
    { field: "straatnaam", headerName: "Straatnaam", width: 150 },
    { field: "huisnummer", headerName: "Huisnummer", width: 100 },
    { field: "postcode", headerName: "Postcode", width: 100 },
    { field: "gemeente", headerName: "Gemeente", width: 150 },
    { field: "land", headerName: "Land", width: 150 },
    { field: "oppervlakte", headerName: "Oppervlakte", width: 100 },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Edit />}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row selection
            handleEditDialogOpen(params.row);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          startIcon={<Delete />}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row selection
            handleDeleteRow(params.row);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Add />}
        onClick={handleCreateDialogOpen}
        style={{ marginBottom: "16px" }}
      >
        Create
      </Button>
      <DataGrid
        rows={gebouwen}
        columns={columns}
        pageSize={5}
        autoHeight
        // checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(newSelection) =>
          setSelectedRow(newSelection.selectionModel[0])
        }
      />

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Gebouw</DialogTitle>
        <DialogContent>
          <Select
            value={editGebouwData.ionProjectId || ""}
            onChange={(e) => {
              setEditGebouwData({
                ...editGebouwData,
                ionProjectId: e.target.value,
              });
            }}
            fullWidth
          >
            {ionProjects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.omschrijving}
              </MenuItem>
            ))}
          </Select>
          {/* <TextField
            label="Omschrijving"
            value={editGebouwData.omschrijving || ""}
            onChange={(e) =>
              setEditGebouwData({
                ...editGebouwData,
                omschrijving: e.target.value,
              })
            }
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button onClick={handleSaveEdit}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Create Dialog */}
      <Dialog open={createDialogOpen} onClose={handleCreateDialogClose}>
        <DialogTitle>Create Gebouw</DialogTitle>
        <DialogContent>
          {/* Autocomplete for ionProjectId */}
          <Autocomplete
            options={ionProjects}
            getOptionLabel={(option) => option.omschrijving}
            value={
              ionProjects.find((p) => p.id === newGebouwData.ionProjectId) ||
              null
            }
            onChange={(_, newValue) => {
              setNewGebouwData({
                ...newGebouwData,
                ionProjectId: newValue ? newValue.id : null,
              });
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Ion Project" />
            )}
          />

          {/* Dropdown for gebouwId */}
          <InputLabel>Gebouw</InputLabel>
          <Select
            value={newGebouwData.gebouwId || ""}
            onChange={(e) =>
              setNewGebouwData({
                ...newGebouwData,
                gebouwId: e.target.value,
              })
            }
            fullWidth
          >
            {allGebouwOptions
              .filter(
                (option) => !gebouwen.some((gebouw) => gebouw.id === option.id),
              )
              .map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.omschrijving}
                </MenuItem>
              ))}
          </Select>

          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose}>Cancel</Button>
          <Button onClick={handleSaveCreate}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectGebouwTable;
