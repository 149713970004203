// import BuildingForm from "../components/BuildingForm";
import BuildingTable from "../components/BuildingTable";
import { AuthenticatedTemplate } from "@azure/msal-react";
import MeterTable from "../components/MeterTable";
import { Box } from "@mui/material";
// import { Container } from "@mui/material";

import React from "react";

const BuildingPage = () => {
  return (
    <Box flex={7} p={{ xs: 0, md: 2 }}>
      <AuthenticatedTemplate>
        <div>
          <BuildingTable />
        </div>
      </AuthenticatedTemplate>
    </Box>
  );
};

export default BuildingPage;
