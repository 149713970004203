import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";

export const CreateNewBuilding = ({ open, onClose, onSubmit }) => {
  const [buildingData, setBuildingData] = useState({
    omschrijving: "",
    straatnaam: "",
    huisnummer: "",
    postcode: "",
    gemeente: "",
    land: "", // defaultwaarde voor land
    oppervlakte: 0, // defaultwaarde voor oppervlakte
    categorie: -1, // In plaats van -1, begin met een lege string
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "oppervlakte") {
      // If the input field is "oppervlakte," parse the value as a number
      setBuildingData({ ...buildingData, [name]: parseFloat(value) });
    } else {
      // For other input fields (e.g., "land"), keep the value as a string
      setBuildingData({ ...buildingData, [name]: value });
    }
  };

  const handleFormSubmit = () => {
    onSubmit(buildingData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Voeg een nieuw gebouw toe</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            name="omschrijving"
            label="Omschrijving"
            value={buildingData.omschrijving}
            onChange={handleInputChange}
          />
          <TextField
            name="straatnaam"
            label="Straatnaam"
            value={buildingData.straatnaam}
            onChange={handleInputChange}
          />
          <TextField
            name="huisnummer"
            label="Huisnummer"
            value={buildingData.huisnummer}
            onChange={handleInputChange}
          />
          <TextField
            name="postcode"
            label="Postcode"
            value={buildingData.postcode}
            onChange={handleInputChange}
          />
          <TextField
            name="gemeente"
            label="Gemeente"
            value={buildingData.gemeente}
            onChange={handleInputChange}
          />
          <TextField
            name="land"
            label="Land"
            value={buildingData.land}
            onChange={handleInputChange}
          />
          <TextField
            name="oppervlakte"
            type="number"
            label="Oppervlakte"
            value={buildingData.oppervlakte}
            onChange={handleInputChange}
          />
          {/* <Select
            name="categorie"
            label="Categorie"
            value={buildingData.categorie}
            onChange={handleInputChange}
          >
            {categorieType.map((categorie) => (
              <MenuItem key={categorie} value={categorie}>
                {categorie}
              </MenuItem>
            ))}
          </Select> */}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuleren
        </Button>
        <Button onClick={handleFormSubmit} color="primary">
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
